.teaser--list {
    
    gap: 3.125rem 0;

    > div {
        display: flex;
    }

    .product-view-slide {
        &::before {
            display: none;
        }
        figure {
            img {
                margin: 0;
                width: 100%;
            }
            &::after {
                display: none;
            }
        }
        .product-view-slide-desc {
            header {
                margin-bottom: 1rem;
                height: 80px;
                > * {
                    font-size: 1.875rem;
                }
            }
            > p:first-of-type {
                &::before {
                    display: block;
                    content: "";
                    width: 40px;
                    height: 1px;
                    background: #707070;
                    margin-bottom: 1rem;
                }
            }
        }
    }
    
}