.accordion {

    .accordion-header {
        > span {
            &::after {
                transition: .15s ease-out;
            }
        }
    }

    .accordion-header[aria-expanded='true'] {
        > span {
            &::after {
                transform: rotate(0deg);
            }
        }
        + .accordion-content {
            opacity: 1;
            transition: all 200ms linear;
            will-change: opacity, max-height;
        }
    }

    .accordion-content {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        transition: opacity 200ms linear, max-height 200ms linear;
        will-change: opacity, max-height;
    }

    .accordion-content-inner {
        padding: 2rem 0;
    }



}

.accordion.accordion-lp {

    .subheader {
        margin-bottom: 3.125rem;
        &::before {
            display: block;
            content: "";
            width: 40px;
            height: 1px;
            background: #707070;
            margin: 0 auto 1.25rem auto;
        }
    }

    .accordion-item {
        margin-bottom: 1rem;
    }

    .btn.accordion-header {
        background-color: rgba(207, 214, 227, .2);
        color: #111;
        font-size: 1rem;
        border: 0;
        font-weight: normal;
        display: flex;
        padding: 1.25em 1.125em;
        > span {
            display: flex;
            position: relative;
            flex: 1;
            width: auto;
            &::after {
                position: absolute;
                right: 0;
                top: 0;
                font-size: 1.25rem;
                color: #003D7C;
            }
        }
        &[aria-expanded='true'],
        &:hover {
            color: #003D7C;
            font-weight: bold;
        }
    }

    .accordion-content-inner {
        border-left: 2px solid rgba(207, 214, 227, .2);
        border-right: 2px solid rgba(207, 214, 227, .2);
        border-bottom: 2px solid rgba(207, 214, 227, .2);
        padding: 1.875rem 3.125rem;
    }

}