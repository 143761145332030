@import 'vars';
@import 'fonts';
@import 'base';
@import 'buttons';
@import 'lang';

#app {
  margin: 0;
  min-height: 100vh;
  font-family: var(--font-helvetica-lt-77-bold-condensed);
  background: radial-gradient(at left bottom, rgba(70, 147, 205, .55) 20%, rgba(0, 24, 63, 1) 100%);
  color: var(--text-color);
  overflow: hidden;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
  position: relative;

  .app-title,
  .main-title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 4vh;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity .5s ease-in-out, visibility .5s ease-in-out;

    @media (max-width: 1024px) {
      text-align: center;
    }

    .page & {
      @media (max-width: 1024px) {
        position: static;
        transform: none;
        width: 100%;
        text-align: center;
        line-height: 1.2
        
      }
    } 
      
    > * {
      margin: 0;
      font-size: clamp(2rem, 3vw, 3.75rem); 
      font-family: var(--font-helvetica-neue-ltw1g-cn);
      font-weight: 400;
    }

    /*
    .frame-content & {
        justify-content: flex-start;
        position: static;
        transform: none;
        margin-bottom: 1.5rem;
        > * {
          font-size: clamp(2rem, 2.55vw, 3.125rem); 
          font-family: var(--font-helvetica-neue-ltw1g-bd-cn);
        }
    }
    */

  }

  .main-frame {
    width: 100%;
    min-height: 100vh;
    display: grid;
    position: fixed; 
    right: 0;
    
    .page & {
      min-height: inherit;
      position: static;
    } 

    .frame-content {
        background-color: transparent;
        width: 80%;
        place-self: center;
        flex-direction: column;
        @media (min-width: $breakpoint-first) {
          flex-direction: row;
        }
        gap: 10px;
        padding-left: 0;
  
        > .frame-col {
          color: #fff;
          position: relative;
          transition: all 1s ease-in-out;
          transition-property: flex, width;
          flex: 1 auto;

          > div {
            position: absolute;
            height: 100%;
            inset: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-bottom: 1rem;
            @media (min-width: $breakpoint-first) {
              padding-bottom: 2rem;
            }
            flex-direction: column;
            cursor: pointer;
            transition: all .5s ease-in-out;
            transition-property: background, height, top;
            
            svg {
              transition: all .5s ease-in-out;
              transition-property: transform, margin;
              margin-bottom: 1rem;
            }
    
            span {
              margin: 0;
              font-weight: 600;
              font-size: clamp(1rem, 1vw, 1.313rem); 
              transition: transform .5s ease-in-out;
  
              font-family: var(--font-helvetica-lt-77-bold-condensed);

            // letter-spacing: 0.110em;
            }
            

          }

          .frame-col-link {
            position: absolute;
            inset: 0;
          }


          &:nth-child(odd) {
            > div {
            background-color: rgba(0, 61, 124, .5);
            }
          }
        
          &:nth-child(even) {
            > div {
            background-color: rgba(74, 149, 208, .5);
            }
          }
          
        }
    }
  }

  .second-frame {
    position: absolute;
    left: -100vw;
    top: 0;
    height: 100%;
    width: 100vw;
    @media (min-width: $breakpoint-first) {
      width: calc(90vw + 1px);
    }
    mix-blend-mode: $blend-mode;
    transition: all 1s ease-in-out;
    // transition-property: width;
    visibility: hidden;
    opacity: 0;
    background: radial-gradient(at left bottom, rgba(70, 147, 205, .55) 20%, rgba(0, 24, 63, 1) 100%);

    .main-title {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 4vh;
      left: 50%;
      transform: translateX(-50%);
      transition: opacity .5s ease-in-out, visibility .5s ease-in-out;

      justify-content: flex-start;
      position: static;
      transform: none;
      margin-bottom: 1.5rem;

      > * {
        font-size: clamp(2rem, 2.55vw, 3.125rem); 
        font-family: var(--font-helvetica-neue-ltw1g-bd-cn);
      }

    }

    .second-frame-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding-left: 4vw;
      opacity: 0;
      transition: all 250ms ease-in;

      .col-title {
        margin-bottom: 1.5rem;
        font-size: clamp(1.35rem, 1.6vw, 1.875rem); 
        font-family: var(--font-helvetica-neue-ltw1g-bd-cn);
      }
      
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4 {
      flex-basis: 33.333vw;

      img, 
      video,
      iframe {
        width: 100%;
        max-width: 100%;
        height: auto;
      }

    }

  }

  .third-frame {
    position: absolute;
    left: -100vw;
    top: 0;
    height: 100%;
    width: 100vw;
    @media (min-width: $breakpoint-first) {
      width: calc(90vw + 1px);
    }
    mix-blend-mode: $blend-mode; 
    transition: all 1s ease-in-out;
    // transition-property: visibility, opacity;
    visibility: hidden;
    opacity: 0;
    background: radial-gradient(at left bottom, rgba(70, 147, 205, .55) 20%, rgba(0, 24, 63, 1) 100%);
    .third-frame-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding-left: 4vw;

      .col-top {
        .main-subtitle {
          * {
            font-size: clamp(1.5rem, 1.6vw, 1.875rem);
          }
        }
        .main-additional-title {
          // font-size: clamp(1rem, 1vw, 1.313rem); 
          font-size: clamp(1.5rem, 1.6vw, 1.875rem);
          margin: 3rem 0 2.5rem 0;
        }
      }
          
      .col-1 {
        .col-subtitle {
          font-size: clamp(1rem, 1vw, 1.313rem); 
        }
      }
      
      .frame-media {
        margin-top: 1rem;
      }

    }

    .col-1,
    .col-2,
    .col-3,
    .col-4 {
      flex-basis: 20vw;

      img, 
      video,
      iframe {
        width: 100%;
        max-width: 100%;
        height: auto;
      }

    }

  }

  .frame-back {
    height: 100%;
    right: 0;
    left: auto;
    width: 10vw;
    position: absolute;
    top: 0;
    z-index: 1;
    cursor: pointer;
  }

  .frame-content {
    background-color: #003D7C;
    width: 100%;
    height: 78vh;
    height: 75vh;
    padding-left: 4vw;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    transition: all .5s ease-in-out;
    transition-property: width;
    margin-top: 5vh;
    a {
      color: #fff;
    }
  }

  .frame-image {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    transition: right .5s ease-in-out;
      

    img {
      max-width: 100%;
      width: 100%;
      height: auto;
      object-fit: contain;
    }

  }

  .frame-list {
    display: flex;
    gap: 5rem;
    flex-wrap: wrap;

    ul {
      list-style: none;
      padding: 0;
      > li {
        
        font-size: clamp(1rem, 1vw, 1.313rem); 
        margin-bottom: .5rem;

        > a {
          background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10.528" height="18.554" viewBox="0 0 10.528 18.554"%3E%3Cpath id="Pfad_24" data-name="Pfad 24" d="M17.371,6.586,9.863,14.094,2.355,6.586.586,8.355l8.393,8.393a1.251,1.251,0,0,0,1.769,0L19.14,8.355Z" transform="translate(-6.586 19.14) rotate(-90)" fill="%23f7f7f7"/%3E%3C/svg%3E%0A');
          background-repeat: no-repeat;
          padding: 0px 0 0 24px;
          background-position: left center;
          transition: all .5s ease-in-out;

          &:hover,
          &:focus-visible,
          &:active {
            background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10.528" height="18.554" viewBox="0 0 10.528 18.554"%3E%3Cpath id="Pfad_24" data-name="Pfad 24" d="M17.371,6.586,9.863,14.094,2.355,6.586.586,8.355l8.393,8.393a1.251,1.251,0,0,0,1.769,0L19.14,8.355Z" transform="translate(-6.586 19.14) rotate(-90)" fill="%23003D7C"/%3E%3C/svg%3E%0A');
            color: var(--primary);
          }
          
        }

        > span {
          background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10.528" height="18.554" viewBox="0 0 10.528 18.554"%3E%3Cpath id="Pfad_24" data-name="Pfad 24" d="M17.371,6.586,9.863,14.094,2.355,6.586.586,8.355l8.393,8.393a1.251,1.251,0,0,0,1.769,0L19.14,8.355Z" transform="translate(-6.586 19.14) rotate(-90)" fill="%23f7f7f7"/%3E%3C/svg%3E%0A');
          background-repeat: no-repeat;
          padding: 0px 0 3px 24px;
          background-position: left center;
        }


        

      }
    }
  }

  .content-list {
    > li {
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10.528" height="18.554" viewBox="0 0 10.528 18.554"%3E%3Cpath id="Pfad_24" data-name="Pfad 24" d="M17.371,6.586,9.863,14.094,2.355,6.586.586,8.355l8.393,8.393a1.251,1.251,0,0,0,1.769,0L19.14,8.355Z" transform="translate(-6.586 19.14) rotate(-90)" fill="%23f7f7f7"/%3E%3C/svg%3E%0A');
      background-repeat: no-repeat;
      padding: 0px 0 3px 24px;
      background-position: left 2px;
      margin-bottom: .5rem;
    }
  }



  .col-2 {
    font-family: var(--font-helvetica-neue-ltw1g-md-cn);
    font-size: clamp(1rem, 1vw, 1.313rem); 
  }

  .col-breadcrumb {
    font-size: clamp(1rem, 1vw, 1.313rem); 
    font-family: var(--font-helvetica-neue-ltw1g-cn);
  }

}





body:not(.open-second-frame):not(.open-third-frame) {

  #app {
  .main-frame {
    .frame-content {
      $frame-col-dev: false;
      @if $frame-col-dev {
        background-color: yellow;
      }

      > .frame-col {

        $frame-col-1: 300px;
        $frame-col-2: 230px;
        $frame-col-3: 150px;
        $frame-col-4: 70px;
        $frame-col-5: 150px;
        $frame-col-6: 230px;

        @if $frame-col-dev {
  
          &.frame-col-1 {
            > div {
              background-color: red !important;
              height: calc(100% - #{$frame-col-1});
              top: $frame-col-1;
            }
          }
          &.frame-col-2 {
            > div {
              background-color: red !important;
              height: calc(100% - #{$frame-col-2});
              top: $frame-col-2;
            }
          }
          &.frame-col-3 {
            > div {
              background-color: red !important;
              height: calc(100% - #{$frame-col-3});
              top: $frame-col-3;  
            }
          }
          &.frame-col-4 {
            > div {
              background-color: red !important;
              height: calc(100% - #{$frame-col-4});
              top: $frame-col-4;
            }
          }
          &.frame-col-5 {
            > div {
              background-color: red !important;
              height: calc(100% - #{$frame-col-5});
              top: $frame-col-5;
            }
          }      
          &.frame-col-6 {
            > div {
              background-color: red !important;
              height: calc(100% - #{$frame-col-6});
              top: $frame-col-6;
            }
          }      
        }

        @media (min-width: $breakpoint-first) {

          // &.frame-col-1:hover,
            &.frame-col-1:focus-visible,
            &.frame-col-1:active,
            &.is--active-1 {
              > div {
                height: calc(100% - #{$frame-col-1});
                top: $frame-col-1;
              }
            }

            // &.frame-col-2:hover,
            &.frame-col-2:focus-visible,
            &.frame-col-2:active,
            &.is--active-2 {
              > div {
                height: calc(100% - #{$frame-col-2});
                top: $frame-col-2;
              }
            }

            // &.frame-col-3:hover,
            &.frame-col-3:focus-visible,
            &.frame-col-3:active,
            &.is--active-3 {
              > div {
                height: calc(100% - #{$frame-col-3});
                top: $frame-col-3;            
              }
            }   

            // &.frame-col-4:hover,
            &.frame-col-4:focus-visible,
            &.frame-col-4:active,
            &.is--active-4 {
              > div {
                height: calc(100% - #{$frame-col-4});
                top: $frame-col-4;
              }
            }   

            // &.frame-col-5:hover,
            &.frame-col-5:focus-visible,
            &.frame-col-5:active,
            &.is--active-5 {
              > div {
                height: calc(100% - #{$frame-col-5});
                top: $frame-col-5
              }
            }

            // &.frame-col-6:hover,
            &.frame-col-6:focus-visible,
            &.frame-col-6:active,
            &.is--active-6 {
              > div {
                height: calc(100% - #{$frame-col-6});
                top: $frame-col-6;
              }
            }

            // deactive if hovering
            &:hover {
              &.is--active-1,
              &.is--active-2,
              &.is--active-3,
              &.is--active-4,
              &.is--active-5,
              &.is--active-6 {
                > div {
                  height: 100%;
                  top: 0;
                }
              }
            }



        }

        /*
        &:hover,
        &:focus-visible,
        &:active {
          > div {
            background-color: rgba(255, 255, 255, 0.4);
            height: calc(100% + 30px);
            top: -30px;
            svg, span {
              transform: translateY(-30px);
            }
          }
        }
        */
        

      @media (min-width: $breakpoint-first) {
        &:hover,
        &:focus-visible,
        &:active {
          > div {
            background-color: rgba(255, 255, 255, 0.4);
            svg, span {
              transform: translateY(-30px);
            }
          }
        }
      }


      }
    }
  }
  }
  

}

body.open-second-frame {

  #app {
    .btn-back,
    .frame-back {
      transition: all 1s ease-in-out;
      opacity: 1 !important;
      visibility: visible !important;
    }

    .frame-image {
        right: -100%;
        opacity: 0;
        @media (min-width: $breakpoint-first) {
          opacity: .7;
        }
    }

    .second-frame {
      left: 0;
      opacity: 1;
      visibility: visible;
      .second-frame-content {
        opacity: 1;
        transition-delay: 1s;      
      }
    }

    .frame-content {
      width: 100%;
      gap: 0;
      justify-content: flex-end;
    }

    .main-frame .app-title {
      opacity: 0;
      visibility: hidden;
    }

    .frame-col {
      flex: 0 !important;
      width: 1.66vw !important;
      opacity: 0;
      @media (min-width: $breakpoint-first) {
        opacity: 1;
      }

      > div {
        position: static !important;
        min-width: 1.66vw;
        padding-bottom: .5rem !important;
        span {
          display: none;
        } 
        svg {
          transform: rotate(-90deg);
          opacity: .4;       
          width: 60%; 
          margin-bottom: 5px !important;
        }
      }
    }
  }



  &.open-third-frame {
    #app {
      .second-frame {
        opacity: 0;
        visibility: hidden;
        // left: -100vw;
      }
      .third-frame {
        left: 0;
        // background-color: rgba(0, 61, 124, 1);
        opacity: 1;
        visibility: visible;
      }
    }
  }

}