nav.meta ul li.search, 
nav.meta ul li.shop, 
nav.meta ul li.contact {
    margin-right: 10px;
}

nav.meta ul li a.link-search::before, 
nav.meta ul li a.link-shop::before, 
nav.meta ul li a.link-contact::before,
nav.meta li.langswitch::before {
    z-index: 2;
    position: relative;
}

nav.meta ul li a.link-contact::before {
    color: #fff;
}

nav.meta ul li a.link-search::after, 
nav.meta ul li a.link-shop::after, 
nav.meta ul li a.link-contact::after,
nav.meta li.langswitch::after {
    content: "";
    position: absolute;
    top: 0;
    background: #cfd6e3;
    width: 100%;
    height: calc(100% + 20px);
    transform: translateY(-13px);
    left: 0;
    z-index: 1;
}

nav.meta ul li a.link-contact::after {
    background: #1d3e79;
}

nav.meta ul li.langswitch {
    min-width: 46px;
}

nav.meta li.langswitch>ul {
    right: 3px;
    top: 40px;
}

// hover
/*
nav.meta ul li a.link-search:hover::before, 
nav.meta ul li a.link-shop:hover::before, 
nav.meta ul li a.link-contact:hover::before,
nav.meta ul li.langswitch:hover::before {
    color: #fff;
}
nav.meta ul li a.link-search:hover::after, 
nav.meta ul li a.link-shop:hover::after, 
nav.meta ul li a.link-contact:hover::after,
nav.meta ul li.langswitch:hover::after {
    background: #1D3E79;
}
*/