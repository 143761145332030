.d-flex {
    display: flex;
}

.flex-column {
	flex-direction: column;
}




html:not([lang=zh]) {
    footer.pageFooter ul li a {
        opacity: 1;
    }

    .pageFooter .hbspt-form {
        margin-left: 4.5rem;
        color: #1C2442;
        .uc-embedding-wrapper {
            border-radius: 0;
        }
        h3 {
            color: inherit;
        }
        .uc-embedding-more-info {
            background: gray;
            &:hover {
                color: #fff;
                background-color: #1D3E79;
            }
        }
        .uc-embedding-accept { 
            background-color: #1D3E79;
            &:hover {
                color: #fff;
                background-color: darken(#1D3E79, 5%);
            }
        }
        a {
            color: inherit;
        }

    }

    footer.pageFooter ul.socialFooter,
    .footerAdditionalColumn {
        padding-top: 3rem;
    }

    .footerAdditionalColumn h3 {
        margin-top: 0;
    }

    /*
    .footerAdditional {
        padding-top: 3rem;
    }
    */

    .newsletterIcon {
        background-color: #fff;
        height: 48px;
        width: 51px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    @media (max-width: ($md - 1)) {

        footer.pageFooter .row {
            margin-bottom: 0;
        }

        .footerAdditionalColumn {
            display: none;
        }

        footer.pageFooter ul.socialFooter {
            padding-top: 0;
            margin-top: 30px;
            justify-content: flex-start;
            margin-left: 6px;
        }

        footer.pageFooter {
            padding-top: inherit;
        }

        footer.pageFooter .secondaryFooter {
            justify-content: center;
            margin-top: 2rem;
            display: grid;
            grid-template-columns: repeat(5, auto);
            justify-content: start;
            gap: 0 1rem;

            li {
                border: none;
                text-align: left;

                a {
                    padding: 10px;
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        .pageFooter>.container .row:nth-child(2) {
            display: block;
        }



    }

    @media (max-width: ($xs - 1)) {
        footer.pageFooter .secondaryFooter {
            grid-template-columns: repeat(2, auto);
        }

        footer.pageFooter ul.socialFooter {
            //justify-content: center;
            //margin-left: 0;
        }
    }
}





