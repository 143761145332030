body#messetool {
  margin: 0;
  *, 
  ::after, 
  ::before {
    box-sizing: border-box;
  }

  
  a {
    text-decoration: none;
    transition: all .5s ease-in-out;
  }

}