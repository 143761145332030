figure {
    &.service-icon {
        [data-product-layout="2"] & {
            figcaption {
                text-align: left;
                h2 {
                    // text-align: center;
                    margin-bottom: 1.5rem;
                    &::after {
                        display: none;
                    }
                }
                ul {
                    padding-left: 0;
                }
            }
        }
    }
}