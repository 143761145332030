$baseUrl: ".."; // don't change this one!

@import '../../../../../sitetemplate-sitetemplate/Resources/Public/src/styles/vars';

/*
header.pageHeader, 
body:not(.mobile-device) nav.mainNav {
    background-color: $yellow; 
}
*/

.no_SpaceBottom {
    margin-bottom: 0;
}

@import 'misc/helper';
@import 'misc/headings';
@import 'misc/layout';

@import 'sections/meta';
@import 'sections/header';
@import 'elements/accordion';
@import 'elements/teaser-list';
@import 'elements/data-table';
@import 'pages/news';
@import 'sections/footer';

// test import messetool styles 
@import '../../export/src/scss/messetool';