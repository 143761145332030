.blanklist{
    margin:0;
    padding:0;
    list-style: none;
    list-style-type: none;
}

.clearfix{
    &::after{
        content: "";
        clear: both;
        display: table;
    }
}

@mixin blanklist {
    margin:0;
    padding:0;
    list-style: none;
    list-style-type: none;
}

@mixin producticon($size){
    display: block;
    content: "";
    height: $size;
    width: $size;
    background: $mainColor;
    margin-bottom: 0.6em;
    // position: absolute;
    // top: 0;
    // left: 0;
    // z-index: 1;
}

@mixin shadowBox {
    background: #fff;
    box-shadow: 0 3px 6px rgba(#000, 0.1);
    position: relative;
}

@mixin iconElement {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    // speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}