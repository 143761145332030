.justify-content-center {
    justify-content: center !important;
}

.py-3 {
    padding-top: 3.125rem; // 50px
    padding-bottom: 3.125rem; // 50px
}

.py-5 {
    padding-top: 5rem; // 80px
    padding-bottom: 5rem; // 80px
}

.mb-5 { 
    margin-bottom: 5rem; // 80px
}

.img-fluid {
    width: 100%;
    height: auto;
}

.mb-2 {
    margin-bottom: 2.25rem; // 36px
}

@media only screen and (min-width: 577px) {
    .text-center-xs {
        text-align: center;
    }
}

.my-3 {
    margin-top: 3.125rem; // 50px
    margin-bottom: 3.125rem; // 50px
}