@font-face {
  font-family: 'HelveticaNeueLTW1G-Bd';
  src: url('../fonts/Helvetica-Neue-LT-W1G/HelveticaNeueLTW1G-Bd.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTW1G-BdCn';
  src: url('../fonts/Helvetica-Neue-LT-W1G/HelveticaNeueLTW1G-BdCn.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTW1G-Cn';
  src: url('../fonts/Helvetica-Neue-LT-W1G/HelveticaNeueLTW1G-Cn.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'HelveticaNeueLTW1G-Lt';
  src: url('../fonts/Helvetica-Neue-LT-W1G/HelveticaNeueLTW1G-Lt.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'HelveticaNeueLTW1G-LtCn_0';
  src: url('../fonts/Helvetica-Neue-LT-W1G/HelveticaNeueLTW1G-LtCn_0.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'HelveticaNeueLTW1G-Md';
  src: url('../fonts/Helvetica-Neue-LT-W1G/HelveticaNeueLTW1G-Md.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTW1G-MdCn';
  src: url('../fonts/Helvetica-Neue-LT-W1G/HelveticaNeueLTW1G-MdCn.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeueLTW1G-Roman';
  src: url('../fonts/Helvetica-Neue-LT-W1G/HelveticaNeueLTW1G-Roman.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}



















@font-face {
  font-family: 'HelveticaNeueLTW1G-MdCn';
  src: url('../fonts/Helvetica-Neue-LT-W1G/HelveticaNeueLTW1G-MdCn.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaLT77BoldCondensed';
  src: url('../fonts/Helvetica_Neue_LT/HelveticaLT77BoldConden.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}