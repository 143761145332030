#app {
    .lang {
        position: relative;
    }

    .menu-lang {
        position: absolute;
        top: .75vw;
        right: 4vw;
        display: flex;
        list-style: none;
        gap: 1vw;
        margin: 0;
        > li {
            opacity: 0;
            visibility: hidden;
            img {
                display: block;
                width: 1.5vw;
            }
        
        }
        &.is--open {
            > li {
                opacity: 1;
                visibility: visible;
                transition: all .5s ease-in-out;
            }
        }
        a {
            color: #fff;
            text-decoration: none;
        }
    }
}



#app[data-lang="de"] {
    [data-lang="en"] {
        display: none !important;
    }
}

#app[data-lang="en"] {
    [data-lang="de"] {
        display: none  !important;
    }
}