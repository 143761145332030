h2,
.h2 {
    [data-product-layout="2"] & {
        
        &.bigger {
font-size: 2.5rem;
        }
        &::after {
            display: none;
        }
    }
}