:root {
    --text-color: #fff;
    --primary: #003D7C;
    --primary-light: #4A95D0;
    
    --font-helvetica-neue-ltw1g-bd: 'HelveticaNeueLTW1G-Bd';
    --font-helvetica-neue-ltw1g-bd-cn: 'HelveticaNeueLTW1G-BdCn';
    --font-helvetica-neue-ltw1g-cn: 'HelveticaNeueLTW1G-Cn';
    --font-helvetica-neue-ltw1g-lt: 'HelveticaNeueLTW1G-Lt';
    --font-helvetica-neue-ltw1g-lt-cn-0: 'HelveticaNeueLTW1G-LtCn_0';
    --font-helvetica-neue-ltw1g-md: 'HelveticaNeueLTW1G-Md';
    --font-helvetica-neue-ltw1g-md-cn: 'HelveticaNeueLTW1G-MdCn'; 
    --font-helvetica-neue-ltw1g-roman: 'HelveticaNeueLTW1G-Roman';

    --font-helvetica-lt-77-bold-condensed: 'HelveticaLT77BoldCondensed';


}

$breakpoint-first: 1200px;
$blend-mode: hue;