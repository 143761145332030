#app {
    
    .btn {

        position: fixed;
        top: 4vh;
        z-index: 100;

        &-home {
            left: 3vw;
            width: 2.5vw;
            height: 2.5vw;
            svg path {
                fill: rgba(70,147,205, .55);
            }
        }

        &-lang {
            right: 3vw;
            width: 2.5vw;
            height: 2.5vw;
            svg path {
                fill: rgba(70,147,205, .55);
            }
        }

        &-back {
            position: absolute;
            bottom: 3.5vh;
            left: 3vw;
            top: auto;
            font-size: clamp(1rem, 1.5vw, 1.5rem); 
            a {
                color: var(--text-color);
            }
            &:hover {
                a {
                    color: var(--primary);
                }
            }
        }

        svg path {
            transition: all .5s ease-in-out;
        }

        &:hover,
        &.is--active {
            svg path {
                fill: var(--primary-light);
            }
        }

    }
}
